<template>
	<select style="width: 100%">
		<slot></slot>
	</select>
</template>

<script>
import _ from 'lodash';

var mountFn = function (vm) {
	//select2에 적용할 jqeury widget options
	var select2option = {
		data: vm.innerOptions,
		disabled: vm.disabled, //비활성화
		placeholder: vm.placeholder, //placeholder
		dropdownAutoWidth: vm.dropdownAutoWidth, //드롭다운 자동폭
		minimumResultsForSearch: vm.minimumResultsForSearch, //검색을 이용할 옵션갯수
	};
	//Select2 부트스트랩
	//이벤트 목록: https://select2.org/programmatic-control/events
	vm.$data.select2 = $(vm.$el).select2(select2option);
	//초기 value 지정
	vm.setValue(vm.value);
	//이벤트 핸들러 설정
	vm.$data.select2
		.on('select2:select', function () {
			vm._suppressTriggerChange = true;
			//console.log("select2 - 컨트롤의 값 변경감지됨", this.value);
			//jqeury event의 this.value대신에 getValue()를 통해 value-type형으로 반환
			vm.$emit('input', vm.getValue());
			vm.$nextTick(function () {
				vm._suppressTriggerChange = false;
			});
		})
		.on('select2:close', function () {
			//veeValidate를 위한 blur처리
			vm.$emit('blur', vm.getValue());
		})
		.on('select2:unselecting', function (e) {
			// 선택 취소한 값
			let unSelectedValue = e.params.args.data.id;
			// 기존 값
			let valList = $(vm.$el).val();
			let chagneValue;
			if (valList.length > 0) {
				// 선택 취소한 값 필터
				chagneValue = valList.filter(val => val != unSelectedValue);
			}
			// 값 넣어주기
			vm.$emit('input', chagneValue);
		});
};

export default {
	data: () => ({
		_suppressTriggerChange: false, //value변경시 select2에 값 설정후 change trigger를 차단할 것인가? (직접 emit하여 다시 들어오는 경우)
		select2: null, // select2 인스턴스
	}),
	//컴포넌트 속성
	props: {
		//options: Option용 데이터 Object Array - 비필수(기본값:null)
		options: { type: [Array], required: false, default: null },
		//id-path: option을 렌더링할때 value를 설정하기 위한 속성명 또는 속성명반환 Function - (기본값:'' => id, value 순으로 사용)
		idPath: { type: [String, Function], required: false, default: '' },
		//text-path: option을 렌더링할때 text를 설정하기 위한 속성명 또는 속성명반환 Function - (기본값:'text')
		textPath: { type: [String, Function], required: false, default: 'text' },
		//value: 선택값(selected value). v-model바인딩에도 사용
		value: { type: [String, Number, Boolean, Object, Array], required: false, default: undefined },
		//value-type: value의 반환타입 Type('Boolean', 'String', 'Number') - (기본값:options의 첫Item의 값으로 자동추론. fallback->'String')
		valueType: { type: [String], required: false, default: null },
		//null-Value: 빈값('')을 선택시 반환할 값 - (기본값:'')
		nullValue: { required: false, default: '' },
		//disabled: 비활성화 여부
		disabled: { type: Boolean, required: false, default: false },
		//placeholder: Placeholder
		placeholder: { type: [String], required: false, default: null },
		//dropdownAutoWidth: 드롭다운 자동폭
		dropdownAutoWidth: { type: Boolean, required: false, default: true },
		//minimumResultsForSearch: 검색을 이용할 옵션갯수
		minimumResultsForSearch: { type: Number, required: false, default: 5 },
	},
	watch: {
		// eslint-disable-next-line no-unused-vars
		options: function (options) {
			//options속성 변경 반영
			$(this.$el).empty().select2({ data: this.innerOptions });
			this._suppressTriggerChange = true;
			this.$emit('input', $(this.$el).val());
			this.$nextTick(function () {
				this._suppressTriggerChange = false;
			});
		},
		value(value) {
			//value속성 변경 반영
			this.setValue(value);
		},
		disabled: function (disabled) {
			//disabled속성 변경 반영
			$(this.$el).prop('disabled', disabled);
		},
	},
	computed: {
		//options에 idPath, textPath를 반영하여 실제 적용할 options
		innerOptions: function () {
			var result = _.map(this.options, item => {
				return {
					id: this.applyPathExpression(item, this.idPath, ['id', 'value']),
					text: this.applyPathExpression(item, this.textPath),
				};
			});
			//console.log("내부Options: " + JSON.stringify(result));
			return result;
		},
		//valueType이 null인 경우 options에서 자동추론
		valueTypeSmart: function () {
			if (!_.isNull(this.valueType)) {
				return this.valueType;
			}
			if (!_.isNull(this.options) && !_.isEmpty(this.options)) {
				var firstItem = this.options[0] || {};
				var firstId = this.applyPathExpression(firstItem, this.idPath, ['id', 'value']);
				if (_.isBoolean(firstId)) {
					return 'Boolean';
				} else if (_.isNumber(firstId)) {
					return 'Number';
				}
			}
			return 'String';
		},
	},
	methods: {
		rebuild: function () {
			this.$nextTick(function () {
				this.$data.select2.off().select2('destroy');
				mountFn(this);
			});
		},
		//value변경시 select에 적용하고 trigger 'change'
		setValue: function (value) {
			if (_.isNull(value) || _.isUndefined(value)) {
				value = '';
			}
			if (!_.isArray(value)) {
				value = value + ''; //toString
			}
			var element = $(this.$el).val(value);
			if (!this._suppressTriggerChange) {
				element.trigger('change');
			}
		},
		//value를 value-type으로 취득
		getValue: function () {
			var value = $(this.$el).val(); //String
			if (_.isNull(value)) {
				return this.nullValue;
			}
			var valueTypeSmart = this.valueTypeSmart;
			if (valueTypeSmart == 'Boolean') {
				value = value.toLowerCase();
				if (_.contains(['true', true, '1', 1, 'on', 'yes', 'y', 't'], value)) {
					return true;
				} else if (_.contains(['false', false, '0', 0, 'off', 'no', 'n', 'f'], value)) {
					return false;
				}
				console.log('[ERROR] select2 component. Cannot convert value to Boolean. value: ', value);
				return this.nullValue;
			} else if (valueTypeSmart == 'Number') {
				if (_.isNull(value) || _.isUndefined(value) || _.isEmpty(value)) {
					return this.nullValue;
				}
				var numberValue = Number(value);
				if (!_.isNaN(numberValue) && _.isFinite(numberValue)) {
					return numberValue;
				}
				console.log('[ERROR] select2 component. Cannot convert value to Number. value: ', value);
				return this.nullValue;
			}
			return value;
		},
		//data Object에서 path를 취득 (path는 porperty명 또는 Function)
		applyPathExpression(data, path, fallbackPaths) {
			if (_.isNull(data) || _.isUndefined(data)) {
				return null;
			}
			//경로값 취득
			if (_.isString(path) && !_.isEmpty(path)) {
				//속성명
				return data[path];
			} else if (_.isFunction(path)) {
				//함수
				return path(data);
			}
			//fallback
			var fallbackPath = _.find(fallbackPaths || [], i => _.has(data, i));
			if (!_.isEmpty(fallbackPath)) {
				return this.applyPathExpression(data, fallbackPath);
			}
			return null;
		},
	},
	mounted: function () {
		mountFn(this);
	},
	beforeDestroy: function () {
		//destory select2 widget
		this.$data.select2.off().select2('destroy');
	},
};
</script>
